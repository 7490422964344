import { firebase } from "@firebase/app";
import '@firebase/auth'
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

// Initialize Firebase
const { REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_MEASUREMENT_ID } = process.env

// Initialize Firebase
const config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID
};


firebase.initializeApp(config);
const auth = firebase.auth();

// const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();


// const database = firebase.database();
const firestore = firebase.firestore();
const storage = firebase.storage();
// const firebaseMessaging = firebase.messaging();

export {
  // database,
  auth,
  firestore,
  storage,
  firebase,
  // firebaseMessaging
  // facebookAuthProvider,
};

export default firebase
/**
 * Helmet is for set title and meta tag
 */

import { Helmet } from 'react-helmet';

const HelmetComponent = (props) => {
    return (
        <Helmet
            titleTemplate={
                props?.title 
                // ? props?.title : 'ARTBOXY'
            }
            defaultTitle={
                props?.defaultTitle
                    // ? props?.defaultTitle
                    // : 'ARTBOXY'
            }>
            <meta
                name="description"
                content={
                    props?.description
                        ? props?.description
                        : 'ARTBOXY'
                }
            />
        </Helmet>
    );
}

export default HelmetComponent
import React from 'react';
import { connect } from "react-redux"
import { withRouter } from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";

const PremiumProfile = (props) => {
    return (
        <Modal
            // show={props.show && props?.isApprovePop ? props.show : false}
            show={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="profile_popup"
        >
            <Button className="closeicon" onClick={props.onHide}><AiOutlineClose /></Button>
            <Modal.Body>
                <div className="premium-profile">
                    <h6 className="submit_verify"><Trans>Congratulations!</Trans></h6>
                    <p><Trans>Your 3 artworks have been accepted by our curators and you are now a verified artist on ARTBOXY.</Trans></p>
                    <p><Trans>You can now share unlimited artworks and blogs on ARTBOXY. </Trans></p>
                    <p><Trans>If you want to exhibit your artworks in our world wide partner galleries each month and benefit from many more advantages take a look at our Premium offers.</Trans></p>
                    <Button className="btn primary-btn" onClick={() => { props.approvePopHandler(false); props?.history.push({ pathname: '/settings/Subscription', state: "fromHome" }) }}><Trans>Click here to learn more about Premium</Trans></Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        isApprovePop: state.isApprovePop
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingHandler: (value) => dispatch({ type: 'IS_LOADING', payload: value }),
        approvePopHandler: (value) => dispatch({ type: 'IS_APPROVEPOP', payload: value }),
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PremiumProfile)))

import { lazy } from "react";

export const routes = {
  homepage: {
    path: "/",
    component: lazy(() => import("../pages/Homepage/Homepage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "",
  },
  // splashscreen: {
  //   path: '/splashscreen',
  //   component: lazy(() => import('../pages/SplashScreenPage/SplashScreenPage')),
  //   exact: true,
  //   wrapRoute: 'LandingRoute',

  // },
  signup: {
    path: "/signup",
    component: lazy(() => import("../pages/Signuppage/Signuppage")),
    exact: true,
    wrapRoute: "AuthRoutes",
  },
  profile: {
    path: "/profile/:profile",
    component: lazy(() => import("../pages/Profilepage/Profilepage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Profile",
  },
  profileedit: {
    path: "/editprofile",
    component: lazy(() => import("../pages/ProfileEditPage/ProfileEditPage")),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Edit profile",
  },
  userartworklist: {
    path: "/artwork/:userid/:artworkid",
    component: lazy(() =>
      import("../pages/UserArtworkListPage/UserArtworkListPage.js")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Artwork",
  },
  userbloglist: {
    path: "/blog/:userid/:blogid",
    component: lazy(() =>
      import("../pages/UserBlogListPage/UserBlogListPage.js")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Blog",
  },
  usersavedpostlist: {
    path: "/saved/:postid",
    component: lazy(() =>
      import("../pages/UserSavedPostList/UserSavedPostList.js")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Saved Post ",
  },
 
  login: {
    path: "/login",
    component: lazy(() => import("../pages/Loginpage/Loginpage")),
    exact: true,
    wrapRoute: "AuthRoutes",
  },
  troublelogin: {
    path: "/troublelogin",
    component: lazy(() => import("../pages/TroubleLoginPage/TroubleLogin")),
    exact: true,
    wrapRoute: "AuthRoutes",
  },
  resetpassword: {
    path: "/resetpassword/:token",
    component: lazy(() =>
      import("../pages/ResetPasswordPage/ResetPasswordPage")
    ),
    exact: true,
    wrapRoute: "AuthRoutes",
  },
  artists: {
    path: "/artists",
    component: lazy(() => import("../pages/ArtistsPage/ArtistsPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Artists",
  },
  category: {
    path: "/allcategory",
    component: lazy(() => import("../pages/AllCategoryPage/AllCategoryPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    // breadcrumb: 'Allcategory'
  },
  allartworkcategory: {
    path: "/allartworkcategory",
    component: lazy(() =>
      import("../pages/AllArtworkCategoryPage/AllArtworkCategoryPage")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    // breadcrumb: 'Allcategory'
  },
  paintings: {
    path: "/technique/:type",
    component: lazy(() => import("../pages/PaintingsPage/Paintingspage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Technique",
  },
  artworksubmission: {
    path: "/artworks/upload",
    component: lazy(() =>
      import("../pages/ArtworkSubmissionPage/ArtworkSubmissionPage")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Artwork Upload",
  },
  allcategorydetails: {
    path: "/allcategory/:id",
    component: lazy(() =>
      import("../pages/AllCategoryDetailPage/AllCategoryDetailPage")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "AllCategory",
  },
  pricing:{
    path: "/pricing/:id",
    component: lazy(() =>
      import("../pages/PricingPage/PricingPage")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
  },
  allartworkdetails: {
    path: "/allartworkcategory/:id",
    component: lazy(() =>
      import(
        "../pages/AllArtworkCategoryDetailsPage/AllArtworkCategoryDetailsPage"
      )
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "AllCategory",
  },
  artworkdetail: {
    path: "/technique/:type/:id",
    component: lazy(() =>
      import("../pages/ArtworkDetailPage/ArtworkDetailPage")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Technique",
  },
  editartwork: {
    path: "/editartwork/:editartid",
    component: lazy(() => import("../pages/EditArtworkPage/EditArtworkPage")),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Edit Artwork",
  },
  editblog: {
    path: "/editblog/:editblogid",
    component: lazy(() => import("../pages/EditBlogPage/EditBlogPage")),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Edit blog",
  },
  contests: {
    path: "/contests",
    component: lazy(() => import("../pages/ContestsPage/ContestsPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Participated Contests",
  },
  contestslist: {
    path: "/contests-list",
    component: lazy(() => import("../pages/ContestsListPage/ContestsListPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Contests List",
  },
  // about: {
  //   path: '/contact',
  //   component: lazy(() => import('../pages/Contactpage/Contactpage')),
  //   exact: true,
  //   wrapRoute: 'PublicRoutes',
  //   breadcrumb: 'Contact'
  // },
  exhibitionpoint: {
    path: "/exhibitionpoint",
    component: lazy(() =>
      import("../pages/ExhibitionPointpage/ExhibitionPointpage")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Exhibition Points",
  },
  artworkexhibition: {
    path: "/artworkexhibition",
    component: lazy(() =>
    import("../pages/ArtworkforExhibitionpage/ArtworkforExhibitionpage")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Artwork Exhibition",
  },
  ArtworkContest:{
    path:"/ArtworkContest",
    component : lazy(()=>
    import("../pages/ArtworkContest/ArtworkContest")),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Contest Add",
  },
  ContestSelection: {
    path: "/contestSelection/:id",
    component: lazy(() =>
      import("../pages/ContestSelection/ContestSelection")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Contest Selection",
  },
  artworkexhibitiondetail: {
    path: "/artworkexhibitiondetail",
    component: lazy(() =>
      import("../pages/ArtworkExhibitionDetailPage/ArtworkExhibitionDetailPage")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Artwork Exhibition",
  },
  postdetails: {
    path: "/postdetails/:postid",
    component: lazy(() => import("../pages/PostDetailsPage/PostDetailsPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Details",
  },
  editartworkexhibitiondetail: {
    path: "/editartworkexhibitiondetail/:editexhibitionartid",
    component: lazy(() =>
      import(
        "../pages/EditArtworkExhibitionDetailPage/EditArtworkExhibitionDetailPage"
      )
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Edit Artwork",
  },
  Exhibitiongallery: {
    path: "/Exhibitiongallery",
    component: lazy(() =>
      import("../pages/ExhibitionGalleryPage/ExhibitionGalleryPage")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
  },
  search: {
    path: "/search",
    component: lazy(() => import("../pages/SearchPage/SearchPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
    breadcrumb: "Search",
  },
  notification: {
    path: "/notification",
    component: lazy(() => import("../pages/NotificationPage/NotificationPage")),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Notifications",
  },
  notificationview: {
    path: "/notification/notificationview",
    component: lazy(() =>
      import("../pages/NotificationViewPage/NotificationViewPage")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Notifications",
  },
  settings: {
    path: "/settings/:settings",
    component: lazy(() =>
      import("../pages/GeneralSettingPage/GeneralSettingPage")
    ),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Settings",
  },
  helpcenter: {
    path: "/helpcenter",
    component: lazy(() => import("../pages/HelpCenterPage/HelpCenterPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  // faqs: {
  //   path: '/faqs',
  //   component: lazy(() => import('../pages/FAQsPage/FAQsPage')),
  //   exact: true,
  //   wrapRoute: 'PublicRoutes',
  // },
  communityguidelines: {
    path: "/community-guidelines",
    component: lazy(() =>
      import("../pages/CommunityGuidelinePage/CommunityGuidelinePage")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  privacypolicy: {
    path: "/privacy-policy",
    component: lazy(() =>
      import("../pages/PrivacyPolicyPage/PrivacyPolicyPage")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  legalterms: {
    path: "/legal-terms",
    component: lazy(() => import("../pages/LegalTermsPage/LegalTermsPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  aboutus: {
    path: "/about-us",
    component: lazy(() => import("../pages/AboutPage/AboutPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  cookiespolicy: {
    path: "/cookies-policy",
    component: lazy(() => import("../pages/CookiePolicyPage/CookiePolicyPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  partnergallery: {
    path: "/partner-galleries",
    component: lazy(() =>
      import("../pages/PartnerGalleryPage/PartnerGalleryPage")
    ),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  whyartboxy: {
    path: "/why-artboxy",
    component: lazy(() => import("../pages/WhyArtboxyPage/WhyArtboxyPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  howitwork: {
    path: "/how-it-work",
    component: lazy(() => import("../pages/HowitWorkPage/HowitWorkPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
  chat: {
    path: "/chats",
    component: lazy(() => import("../pages/ChatPage/ChatPage")),
    exact: true,
    wrapRoute: "ProtectedRoutes",
  },
  // paymentorder: {
  //   path: '/payments',
  //   component: lazy(() => import('../pages/PaymentOrderPage/PaymentOrderPage')),
  //   exact: true,
  //   wrapRoute: 'ProtectedRoutes',
  //   breadcrumb: 'Notifications'
  // },
  // updatepaymentorder: {
  //   path: '/updatepayments',
  //   component: lazy(() => import('../pages/UpdatePaymentOrderPage/UpdatePaymentOrderPage')),
  //   exact: true,
  //   wrapRoute: 'ProtectedRoutes',
  //   breadcrumb: 'Notifications'
  // },
  sucess: {
    path: "/sucess",
    component: lazy(() => import("../pages/SucessPage/SucessPage")),
    exact: true,
    wrapRoute: "ProtectedRoutes",
    breadcrumb: "Notifications",
  },
  cmspages: {
    path: "/:cms/:language",
    component: lazy(() => import("../pages/CmsPage/CmsPage")),
    exact: true,
    wrapRoute: "PublicRoutes",
  },
};

export const renderRoutes = Object.entries(routes);

import React, { useState, useEffect } from 'react';
import { connect } from "react-redux"
import { withRouter } from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { firestore } from '../../../firebase/config';
import { ImageMap } from '@qiuz/react-image-map';
import { setDefaultLocale } from 'javascript-time-ago';
import { useProductFruitsApi } from 'react-product-fruits';
const { REACT_APP_PREFIX } = process.env;


const PopImage = (props) => {
    const [popImages, setPopImages] = useState([])
    const [CheckMonthEnd, setCheckMonthEnd] = useState("");
    const [enabledPopUp, setEnabledPopup] = useState()
    const [isTourRunning, setIsTourRuuning] = useState(false)
    
let test;

   
    useEffect(() => {
        checkEnabled();
        checkMonthEndForExhibition();
    }, [enabledPopUp])
    // useEffect(async ()=>{
    //     if(enabledPopUp == true)
    //     {
    //         console.log("qqqqqqqqqqq");
    //         await firestore
    //         .collection(`${REACT_APP_PREFIX}pop_image`)
    //         .onSnapshot((querySnapshot) => {
    //             let techniques = querySnapshot.docs.map((doc) => {
    //                 let techniquesdata = doc.data();
    //                 techniquesdata.docId = doc.id
    //                 // let techniquesdata = doc.data();
    //                 console.log(techniquesdata,"techniquesdata");
    //                 return techniquesdata
    //             });
    //          setPopImages(techniques);


    //         });
    //     }
    // },[])
    const setpopupimage = async(val) =>  {
        if (val === 1 ){
            await firestore
            .collection(`${REACT_APP_PREFIX}pop_image`)
            .onSnapshot((querySnapshot) => {
                let techniques = querySnapshot.docs.map((doc) => {
                    let techniquesdata = doc.data();
                    techniquesdata.docId = doc.id
                    return techniquesdata
                });
              
                    setPopImages(techniques);
                 
            


            }) 
        }else{ setPopImages([])}


;

    };
    const checkEnabled = async () => {
        const data = await firestore.collection(`${REACT_APP_PREFIX}pop_image`).onSnapshot((querySnapshot) => {
            let postData = querySnapshot.docs.map((doc) => {
                let techniquesdata = doc.data();
                techniquesdata.docId = doc.id
                return techniquesdata
            });
            let data = postData[0]?.enabled;
            setEnabledPopup(data);
            test=data
            if(data===0){
                setPopImages([])
            }
            else{
                    setpopupimage(test)

                }

        });

    }
    

    useProductFruitsApi(
        (api) => {
          const isAnyTourRunning =api.tours
            .getTours()
            .some((tour) => tour.isRunning);
          if (isAnyTourRunning) {
            props.onHide();
            // code to hide the thing
          } else {
            // code to show the thing
          }
        },
        [props]
      );

    const checkMonthEndForExhibition = () => {

        var today = new Date();
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, -1);

        setCheckMonthEnd(today < lastDayOfMonth ? "open" : "close")

    }
    const mapArea = [
        {
            // style: { background: "rgba(255, 0, 0, 0.5)" },
            onMouseclick: () => console.log("map onMouseOver")
        },
        {
            // style: { background: "rgba(255, 0, 0, 0.5)" },
            onMouseOver: () => console.log("map onMouseOver")
        }
    ];
    const popupclose = () => {
        props.onHide();

    }
    const popupdirection = () => {
        props.approvePopHandler(false);
        props?.history.push({ pathname: '/artworkexhibition' })
    }

    const onMapClick = (area, index) => {
        { (index == 0 ? popupclose() : popupdirection()) }
    };
    return (
        <Modal
        show = {CheckMonthEnd == "open" ? props?.checkRoutesPopImage == false? props.show && popImages[0]?.[props?.user?.language] ? props.show : false : false : ""}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="home-popup"
        >
            {/* {/ <Button className="closeicon" onClick={props.onHide}><AiOutlineClose /></Button> /} */}
            <Modal.Body>
                <div className="offer_profile">
                    <ImageMap
                        className="image-map"
                        src={popImages[0]?.[props?.user?.language]}
                        map={mapArea}
                        onMapClick={onMapClick}
                    />
                    {/* {/ <Button className="btn-trial" onClick={() => { props.approvePopHandler(false); props?.history.push({ pathname: '/artworkexhibition' }) }}><Trans>start your free trial</Trans></Button> /} */}
                </div>
            </Modal.Body>
        </Modal>
    );
}
const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        isApprovePop: state.isApprovePop
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingHandler: (value) => dispatch({ type: 'IS_LOADING', payload: value }),
        approvePopHandler: (value) => dispatch({ type: 'IS_APPROVEPOP', payload: value }),
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PopImage)))

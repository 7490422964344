import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { firestore } from '../firebase/config'
import CheckLogout from './CheckLogout';
const { REACT_APP_PREFIX } = process.env;

const ExhibitionRoutes = (props) => {
    const Component = props.component;
    const userID = localStorage.getItem('userId')

    useEffect(() => {
        async function fetchData() {
            try {
                props.languageHandler(localStorage.getItem("i18nextLng") ?? 'en')
                const Id = localStorage.getItem("userId")
                if (Id) {
                    let BlockUserId = []
                    await firestore.collection(`${REACT_APP_PREFIX}blocked_user`)
                        .doc(Id)
                        .collection('blocked')
                        .onSnapshot(async (querySnapshot) => {
                            await Promise.all(querySnapshot.docs.map(async (blockdoc) => {
                                BlockUserId.push(blockdoc.id)
                            }))
                        })
                    await firestore.collection(`${REACT_APP_PREFIX}blocked_user`)
                        .onSnapshot(async (querySnapshotBlock) => {
                            await Promise.all(querySnapshotBlock.docs.map(async (blockUserdoc) => {
                                if (blockUserdoc.id !== Id) {
                                    await firestore.collection(`${REACT_APP_PREFIX}blocked_user`)
                                        .doc(blockUserdoc.id)
                                        .collection('blocked')
                                        .onSnapshot(async (querySnapshotBlockData) => {
                                            await Promise.all(querySnapshotBlockData.docs.map(async (blockdocData) => {
                                                if (blockdocData.id == Id) {
                                                    BlockUserId.push(blockUserdoc.id)
                                                }
                                            }))
                                        })
                                }
                            }))
                        })

                    props.blockUserHandler(BlockUserId);

                    await firestore.collection(`${REACT_APP_PREFIX}users`).doc(Id)
                        .onSnapshot((doc) => {
                            if (doc.exists && localStorage.getItem("userId")) {
                                const documentData = doc.data();
                                props.userHandler(documentData);
                                if (documentData.is_deleted === true || documentData.status === 'deleted') {
                                    localStorage.removeItem('userId');
                                    props.deleteHandler(true);
                                    setTimeout(() => {
                                        props.deleteHandler(false);
                                        props.logOutHandler();
                                    }, 5000);
                                    props.history.push('/')
                                }
                                if (documentData.status === 'rejected') {
                                    localStorage.removeItem('userId');
                                    props.suspendHandler(true);
                                    setTimeout(() => {
                                        props.suspendHandler(false);
                                        props.logOutHandler();
                                    }, 5000);
                                    props.history.push('/')
                                }
                            } else {
                                localStorage.removeItem('userId');
                                props.logOutHandler();
                                props.history.push('/')
                            }
                        });
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [userID]);

    return (props.isLoggedIn && userID && userID !== '' && props.user?.subscription_plan != 'Standard' ?
        <CheckLogout><Component to={{ pathname: '/artworkexhibition' }} /></CheckLogout>
        :
        <Redirect to={{ pathname: '/' }} />
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        isLoggedIn: state.isLoggedIn,
        isSuspended: state.isSuspended,
        isDeleted: state.isDeleted
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        userHandler: (value) => dispatch({ type: 'USER_HANDLER', payload: value }),
        suspendHandler: (value) => dispatch({ type: 'SUSPEND', payload: value }),
        logOutHandler: () => dispatch({ type: 'LOG_OUT' }),
        deleteHandler: (value) => dispatch({ type: 'DELETE', payload: value }),
        blockUserHandler: (value) => dispatch({ type: 'BLOCK_USER_HANDLER', payload: value }),
        languageHandler: (value) => dispatch({ type: 'LANGUAGE', payload: value }),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExhibitionRoutes))

const intialState = {
    onBoarding: false,
    isLoggedIn: false,
    user: null,
    allUser: [],
    blockUser: [],
    isLoading: false,
    isSuspended: false,
    isDeleted: false,
    deviceId: null,
    isApprovePop: false,
    language: "en",
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    },
    exhibtionArtworkData: [],
    popImage:false,
    checkRoutesPopImage:false
}

const Reducer = (state = intialState, action) => {
    switch (action.type) {
        case "Is_OnBoarding": {
            state = {
                ...state,
                onBoarding: action.payload
            }
            break;
        }
        case "LOGGED_IN": {
            state = {
                ...state,
                isLoggedIn: action.payload
            }
            break;
        }
        case "LANGUAGE": {
            state = {
                ...state,
                language: action.payload
            }
            break;
        }
        case "USER_HANDLER": {
            state = {
                ...state,
                user: action.payload
            }
            break;
        }
        case "ALL_USER_HANDLER": {
            state = {
                ...state,
                allUser: action.payload
            }
            break;
        }
        case "BLOCK_USER_HANDLER": {
            state = {
                ...state,
                blockUser: action.payload
            }
            break;
        }
        case "IS_LOADING": {

            state = {
                ...state,
                isLoading: action.payload
            }
            break;
        }
        case "IS_APPROVEPOP": {

            state = {
                ...state,
                isApprovePop: action.payload
            }
            break;
        }

        case "DEVICE_ID": {
            state = {
                ...state,
                deviceId: action.payload
            }
            break;
        }
        case "POP_IMAGE": {
            state = {
                ...state,
                popImage: action.payload
            }
            break;
        }
        case "POP_IMAGE_ROUTE": {
            state = {
                ...state,
                checkRoutesPopImage: action.payload
            }
            break;
        }
        case "LOG_OUT": {
            state = {
                ...state,
                onBoarding: false,
                isLoggedIn: false,
                user: null,
                isLoading: false
            }
            break;
        }
        case "SUSPEND": {
            state = {
                ...state,
                isSuspended: action.payload
            }
            break;
        }
        case "DELETE": {
            state = {
                ...state,
                isDeleted: action.payload
            }
            break;
        }
        case 'SWITCH_LANGUAGE': {
            state = {
                ...state,
                locale: action.payload
            };
            break;
        }
        case 'EXHIBITION_ARTWORK_REDIRECT': {
            state = {
                ...state,
                exhibtionArtworkData: action.payload
            };
            break;
        }
        default:
            break;
    }
    return state;
}

export default Reducer;
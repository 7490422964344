import React, { Suspense, useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import SampleContext from "./SampleContext";
import { renderRoutes } from "./routes/routes";
import AuthRoutes from "./service/AuthRoutes";
import ProtectedRoutes from "./service/ProtectedRoutes";
import ExhibitionRoutes from "./service/ExhibitionRoutes";
import { ProductFruits,useProductFruitsApi } from "react-product-fruits";
import { Spinner } from "react-bootstrap";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/CSS/main.css";
import "./assets/CSS/responsive.css";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { firestore, firebase } from "./firebase/config";
import UserAccountSuspended from "./components/Modals/UserAccountSuspended/UserAccountSuspended";
import UserAccountDeleted from "./components/Modals/UserAcoountDelete/UserAcoountDelete";
import ProfilePremiumModal from "./components/Modals/Profile-premiumModal/Profile-premiumModal";
import HelmetComponent from "./components/Helmet/Helmet";
import { auth } from "./firebase/config";
import PopImage from "./components/Modals/PopImage/PopImage";
import CheckLogout from "./service/CheckLogout";
const { REACT_APP_PREFIX } = process.env;

const useComponentWillMount = (func) => {
  const willMount = useRef(true);
  if (willMount.current) {
    func();
  }
  useComponentDidMount(() => {
    willMount.current = false;
  });
};

const useComponentDidMount = (func) => useEffect(func, [func]);

function App(props) {
  let location = useLocation();
  const [gettingStarted, setGettingStarted] = useState(false);
  const [CheckMonthEnd, setCheckMonthEnd] = useState(false);
  const [val, setVal] = useState(0);
  const [time, setTime] = useState(false);
  const [tourHit, setTourHit] = useState(0);
  const userInfoIsLoaded = true;
  const [monthend, setmonthend] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [userInfo, setuserInfo] = useState({
    username: "",
    email: false,
    firstname: CheckMonthEnd,
    props: { userId: props?.user?.user_id },
    role: "phase1",
    language: props?.user?.language,
    signUpAt : `${monthend.days === 0 ? "" : `${monthend.days}d `}${monthend.hours === 0 ? "" : `${monthend.hours}h `}${monthend.minutes === 0 ? `${monthend.seconds}s` : `${monthend.minutes}m`}`
  });
  useEffect(() => {
    let intervalId;
    const checkMonthEndForExhibition = async () => {
      const timestampDocRef = firestore
        .collection("dev_timestamp")
        .doc("currentTime");
      const snapshot = await timestampDocRef.get();
      const timestampData = snapshot.data();
      if (timestampData) {
        setTime(true);
        const getdate = timestampData.timestamp?.toDate();
        var lastDayOfMonth = new Date(
          getdate.getFullYear(),
          getdate.getMonth() + 1,
          -1
        );
        const todayDate = timestampData.timestamp?.toDate().valueOf();
        const endOfMonth = moment(todayDate).add(1, 'month').startOf('month');
        const diff = endOfMonth.diff(todayDate);
        const duration = moment.duration(diff);
        setmonthend({
          days: duration.days(),
          minutes: duration.minutes(),
          hours: duration.hours(),
        });

        setCheckMonthEnd(
          getdate.valueOf() < lastDayOfMonth.valueOf() ? true : false
        );
      }
    };
    const startInterval = () => {
      checkMonthEndForExhibition(); // Run the function initially

      intervalId = setInterval(() => {
        checkMonthEndForExhibition();
      }, 1000); // Interval duration in milliseconds (1 minute = 60000ms)
    };

    startInterval();

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, []);
  const [popup, setPopUp] = useState(false);
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const [popImageShow, setPopImageShow] = useState(false);

  const VerificationClose = async () => {
    // setIsVerificationOpen(false)
    props.approvePopHandler(false);
    const Id = localStorage.getItem("userId");
    if (Id) {
      const verificationNotification = await firestore
        .collection(`${REACT_APP_PREFIX}notification`)
        .doc(Id)
        .collection("details")
        .where("is_seen", "==", false)
        .where(
          "body",
          "==",
          "Congratulations, your artworks have been accepted by the ARTBOXY curators!"
        )
        .orderBy("create_at", "asc")
        .limit(1)
        .get();
      if (
        !verificationNotification.empty &&
        verificationNotification?.docs[0]?.id
      ) {
        await firestore
          .collection(`${REACT_APP_PREFIX}notification`)
          .doc(props.user?.user_id)
          .collection("details")
          .doc(verificationNotification?.docs[0]?.id)
          .update({
            is_seen: true,
          });
      }
    }
  };
  if (
    props?.user?.subscription_plan == "Standard" &&
    !props?.user?.trial_plan
  ) {
    document.addEventListener("visibilitychange", () => {
      localStorage.setItem("popimage", "no");
    });

    document.addEventListener("click", () => {
      if (
        ![
          "/artworkexhibition",
          "/settings/:settings",
          "/settings/Subscription",
          "/sucess",
          "contests-list",
          "/contests",
          "/contestSelection/${id}",
        ].includes(location.pathname)
      ) {
        setTimeout(() => {
          if (
            localStorage.getItem("popimage") &&
            localStorage.getItem("popimage") == "no"
          ) {
            setPopImageShow(true);
            localStorage.setItem("popimage", "yes");
          }
        }, 10000);
      } else {
        setPopImageShow(false);
        localStorage.setItem("popimage", "yes");
      }
    });
    if (props?.popImage == true) {
      if (
        ![
          "/artworkexhibition",
          "/settings/:settings",
          "/settings/Subscription",
          "/sucess",
          ,
          "contests-list",
          "/contests",
          "/contestSelection/${id}",
        ].includes(location.pathname)
      ) {
        setTimeout(() => {
          if (
            localStorage.getItem("popimage") &&
            localStorage.getItem("popimage") == "no"
          ) {
            setPopImageShow(true);
            localStorage.setItem("popimage", "yes");
          }
        }, 10000);
        props.popHandler(false);
      } else {
        setPopImageShow(false);
        localStorage.setItem("popimage", "yes");
      }
    }
  } else if (
    props?.user?.subscription_plan == ("Free" || "Monthly" || "Yearly")
  ) {
    localStorage.removeItem("popimage");
  }
  useEffect(() => {
    setuserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      username: props?.user?.full_name || "",
      email:
        props?.user?.artwork_status == true
          ? props?.user?.artwork_status
          : false || false,
      firstname: CheckMonthEnd,
      props: { ...prevUserInfo.props, userId: props?.user?.user_id },
      role : props?.user?.onBoardPhase1 === undefined || props?.user?.onBoardPhase1 === false
      ? "phase1"
      : props?.user?.onBoardPhase2 === undefined || props?.user?.onBoardPhase2 === false
      ? "phase2"
      : "done",
      language: props?.user?.language,
      signUpAt: `${monthend.days === 0 ? "" : `${monthend.days}d `}${monthend.hours === 0 ? "" : `${monthend.hours}h `}${monthend.minutes === 0 ? `${monthend.seconds}s` : `${monthend.minutes}m`}`
    }));
  }, [props, CheckMonthEnd]);
  const isSeenNotification = async (user_id) => {
    await firestore
      .collection(`${REACT_APP_PREFIX}notification`)
      .doc(user_id)
      .collection("details")
      .where("is_seen", "==", false)
      .where(
        "body",
        "==",
        "Congratulations, your artworks have been accepted by the ARTBOXY curators!"
      )
      .orderBy("create_at", "asc")
      .limit(1)
      .onSnapshot(async (snapshot) => {
        if (snapshot.docs.length > 0) {
          // setIsVerificationOpen(true)
          props.approvePopHandler(true);
        }
      });
  };
  useComponentWillMount(() => {
    props.languageHandler(localStorage.getItem("i18nextLng") ?? "en");
    const userID = localStorage.getItem("userId");
    if (userID) {
      props.loggedInHandler(true);
    }
    async function fetchData() {
      try {
        const Id = localStorage.getItem("userId");
        if (Id) {
          await isSeenNotification(Id);
          let BlockUserId = [];
          await firestore
            .collection(`${REACT_APP_PREFIX}blocked_user`)
            .doc(Id)
            .collection("blocked")
            .onSnapshot(async (querySnapshot) => {
              await Promise.all(
                querySnapshot.docs.map(async (blockdoc) => {
                  BlockUserId.push(blockdoc.id);
                })
              );
            });
          await firestore
            .collection(`${REACT_APP_PREFIX}blocked_user`)
            .onSnapshot(async (querySnapshotBlock) => {
              await Promise.all(
                querySnapshotBlock.docs.map(async (blockUserdoc) => {
                  if (blockUserdoc.id !== Id) {
                    await firestore
                      .collection(`${REACT_APP_PREFIX}blocked_user`)
                      .doc(blockUserdoc.id)
                      .collection("blocked")
                      .onSnapshot(async (querySnapshotBlockData) => {
                        await Promise.all(
                          querySnapshotBlockData.docs.map(
                            async (blockdocData) => {
                              if (blockdocData.id == Id) {
                                BlockUserId.push(blockUserdoc.id);
                              }
                            }
                          )
                        );
                      });
                  }
                })
              );
            });

          await firestore
            .collection(`${REACT_APP_PREFIX}users`)
            .where("status", "==", "rejected")
            .onSnapshot(async (querySnapshot) => {
              await Promise.all(
                querySnapshot.docs.map(async (suspendUser) => {
                  BlockUserId.push(suspendUser.id);
                })
              );
              props.blockUserHandler(BlockUserId);
            });
          await firestore
            .collection(`${REACT_APP_PREFIX}users`)
            .where("is_deleted", "==", true)
            .onSnapshot(async (querySnapshot) => {
              await Promise.all(
                querySnapshot.docs.map(async (suspendUser) => {
                  BlockUserId.push(suspendUser.id);
                })
              );
              props.blockUserHandler(BlockUserId);
            });
          props.blockUserHandler(BlockUserId);

          await firestore
            .collection(`${REACT_APP_PREFIX}users`)
            .doc(Id)
            .onSnapshot(async (doc) => {
              if (doc.exists && localStorage.getItem("userId")) {
                if (localStorage.getItem("userId") == doc.data()?.user_id) {
                  const documentData = doc.data();
                  props.userHandler(documentData);
                  if (
                    documentData.is_deleted === true ||
                    documentData.status === "deleted"
                  ) {
                    await firestore
                      .collection(`${REACT_APP_PREFIX}users`)
                      .doc(documentData?.user_id)
                      .update({ online: false });
                    localStorage.removeItem("userId");
                    props.deleteHandler(true);
                    setTimeout(() => {
                      props.deleteHandler(false);
                      props.logOutHandler();
                      props.history.push("/");
                    }, 5000);
                  }
                  if (documentData.status === "rejected") {
                    await firestore
                      .collection(`${REACT_APP_PREFIX}users`)
                      .doc(documentData?.user_id)
                      .update({ online: false });
                    localStorage.removeItem("userId");
                    props.suspendHandler(true);
                    setTimeout(() => {
                      props.suspendHandler(false);
                      props.logOutHandler();
                      props.history.push("/");
                    }, 5000);
                  }
                }
              } else {
                localStorage.removeItem("userId");
                props.logOutHandler();
                props.history.push("/");
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  });
  useProductFruitsApi((api) => {
    api.tours.listen('tour-skipped', (tourId) => { 
     if(tourId)
     {
      firestore
      .collection(`${REACT_APP_PREFIX}users`)
      .doc(props?.user?.user_id)
      .update({
        onBoardPhase1 : true
      });
     }
     })
    
    }, [props])
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    const log = lang.includes("en")
      ? localStorage.setItem("i18nextLng", "en")
      : lang;
    const Id = localStorage.getItem("userId");
    const data = async () => {
      if (Id) {
        if (
          ![
            "/artworkexhibition",
            "/settings/:settings",
            "/settings/Subscription",
            "/sucess",
          ].includes(location.pathname)
        ) {
          const exhibitionArtData = await firestore
            .collection(`${REACT_APP_PREFIX}exhibition_art_data`)
            .where("user_id", "==", Id)
            .get();
          if (!exhibitionArtData.empty) {
            await Promise.all(
              exhibitionArtData.docs.map(async (art) => {
                await firestore
                  .collection(`${REACT_APP_PREFIX}exhibition_art_data`)
                  .doc(art.id)
                  .delete();
              })
            );
          }
        }
      }
    };
    data();
    // if (
    //   !location.pathname.includes("troublelogin") &&
    //   !location.pathname.includes("profile") &&
    //   !location.pathname.includes("resetpassword")
    // ) {
    // const flag = localStorage.getItem("getStarted");
    // if (typeof flag === "undefined" || flag !== "1") {
    //   setGettingStarted(true);
    // }
    // } else {
    //   setGettingStarted(false);
    // }
    if (
      [
        "/artworkexhibition",
        "/settings/:settings",
        "/settings/Subscription",
        "/sucess",
        "contests-list",
        "/contests",
        "/contestSelection/${id}",
      ].includes(location.pathname)
    ) {
      props.popRouteHandler(true);
      setPopImageShow(false);
      localStorage.setItem("popimage", "yes");
    } else {
      props.popRouteHandler(false);
    }
  }, [location.pathname]);

  const { isLoading } = props;

  const checkOnline = async (e) => {
    if (props?.user?.user_id) {
      let id = props.user.user_id;
      if (e === "online") {
        await firestore.collection(`${REACT_APP_PREFIX}users`).doc(id).update({
          online: true,
          update_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
        setTimeout(async () => {
          await firestore
            .collection(`${REACT_APP_PREFIX}users`)
            .doc(id)
            .update({ online: false });
        }, 5 * 60 * 1000);
      }
    }
  };
  // if (window.navigator.onLine) {
  //   props.isLoadingHandler(false)
  // }else if (window.navigator.onLine == false) {
  //   setTimeout(()=>{
  //     if (!props.isLoading || props.isLoading == false) {
  //       toast.error('please connect internet')
  //     }
  //   },3000)
  //   props.isLoadingHandler(true)

  // }
  return (
    <>
      <SampleContext.Provider
        value={{
          val,
          tourHit,
          increment: () => {
            setVal((i) => i + 1);
          },
          incrementTourHit: async() => {
          setTourHit((i) => i + 1);
          },
        }}
      >
        <HelmetComponent
          title="ARTBOXY"
          defaultTitle="ARTBOXY | Show your art worldwide"

        />
        <div className="App" onClick={() => checkOnline("online")}>
          <Suspense
            fallback={
              <div className="loader">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading….</span>
                </Spinner>
              </div>
            }
          >
            {isLoading && (
              <div className="loader">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}
            <ToastContainer
              position="top-center"
              hideProgressBar={true}
              autoClose={3000}
              newestOnTop={true}
              closeOnClick={true}
              draggable={false}
              rtl={false}
            />
            <UserAccountSuspended show={props.isSuspended} />
            <UserAccountDeleted delete={props.isDeleted} />
            {/* {props?.isApprovePop ?
            <ProfilePremiumModal show={props?.isApprovePop} onHide={VerificationClose} />
            : null} */}
            {props?.user?.subscription_plan == "Standard" &&
            !props?.user?.trial_plan ? (
              <PopImage
                show={popImageShow}
                onHide={() => setPopImageShow(false)}
                checkRoutesPopImage={props?.checkRoutesPopImage}
              />
            ) : null}
            <Switch>
              {renderRoutes.map(([key, route]) => {
                if (route.wrapRoute && route.wrapRoute !== "") {
                  if (route.wrapRoute === "AuthRoutes") {
                    return (
                      <AuthRoutes
                        key={key}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                  } else if (route.wrapRoute === "ProtectedRoutes") {
                    return (
                      <ProtectedRoutes
                        key={key}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                  } else if (route.wrapRoute === "ExhibitionRoutes") {
                    return (
                      <ExhibitionRoutes
                        key={key}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                  }
                  //  else if (route.wrapRoute === "LandingPageRoute") {
                  //   return (
                  //     <LandingPageRoute
                  //       key={key}
                  //       path={route.path}
                  //       exact={route.exact}
                  //       component={route.component}
                  //     />
                  //   );
                  else {
                    return (
                      <Route
                        key={key}
                        path={route.path}
                        exact={route.exact}
                        // component={route.component}
                        render={() => {
                          const Component = route.component;
                          return (
                            <CheckLogout>
                              <Component />
                            </CheckLogout>
                          );
                        }}
                      />
                    );
                  }
                } else {
                  return (
                    <Route
                      key={key}
                      path={route.path}
                      exact={route.exact}
                      // component={route.component}
                      render={() => {
                        const Component = route.component;
                        return (
                          <CheckLogout>
                            <Component />
                          </CheckLogout>
                        );
                      }}
                    />
                  );
                }
              })}
              <Redirect from="*" to="/" />
            </Switch>
            {/* <Footermain /> */}
          </Suspense>
        </div>
        {  userInfoIsLoaded && userInfo.username && props.role != "done" && (
          <ProductFruits
            workspaceCode="f2aDjUVgPbycRIk8"
            language={
              props?.user?.language === "sp"
                ? "es"
                : props?.user?.language === "in"
                ? "it"
                : props?.user?.language === "ge"
                ? "de"
                : props?.user?.language
            }
            user={userInfo}
            debug={true}
          />
        )}
      </SampleContext.Provider>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    onBoarding: state.onBoarding,
    isLoading: state.isLoading,
    isSuspended: state.isSuspended,
    isDeleted: state.isDeleted,
    user: state.user,
    isApprovePop: state.isApprovePop,
    popImage: state.popImage,
    checkRoutesPopImage: state.checkRoutesPopImage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loggedInHandler: (value) => dispatch({ type: "LOGGED_IN", payload: value }),
    userHandler: (value) => dispatch({ type: "USER_HANDLER", payload: value }),
    blockUserHandler: (value) =>
      dispatch({ type: "BLOCK_USER_HANDLER", payload: value }),
    suspendHandler: (value) => dispatch({ type: "SUSPEND", payload: value }),
    deleteHandler: (value) => dispatch({ type: "DELETE", payload: value }),
    deviceIdHandler: (value) => dispatch({ type: "DEVICE_ID", payload: value }),
    logOutHandler: () => dispatch({ type: "LOG_OUT" }),
    isLoadingHandler: (value) =>
      dispatch({ type: "IS_LOADING", payload: value }),
    languageHandler: (value) => dispatch({ type: "LANGUAGE", payload: value }),
    approvePopHandler: (value) =>
      dispatch({ type: "IS_APPROVEPOP", payload: value }),
    popHandler: (value) => dispatch({ type: "POP_IMAGE", payload: value }),
    popRouteHandler: (value) =>
      dispatch({ type: "POP_IMAGE_ROUTE", payload: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

import React from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation, Trans } from "react-i18next";

const UserAccountSuspended = (props) => {

    return (
        <Modal
            show={props.show ? props.show : false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="artwork-end">
                    <h6 className="submit_verify"><Trans>Your account has been suspended. Please contact our support.</Trans></h6>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withTranslation()(UserAccountSuspended)
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { firestore, firebase } from "../firebase/config";
import { toast } from "react-toastify";
import { withTranslation, Trans } from "react-i18next";
const { REACT_APP_PREFIX } = process.env;
const CheckLogout = (props) => {
    const [popup, setPopUp] = useState(false);
    const { t } = props;
    const checktoken = async () => {
        const token = localStorage.getItem('authToken')
        const checkdata = async () => {
            const deviceDataCheck = await firestore.collection(`${REACT_APP_PREFIX}device_data`).doc(props?.user?.user_id).get()
            if (deviceDataCheck.exists) {
                let authToken = deviceDataCheck.data().device_id;
                if (authToken != token) {
                    setPopUp(true);
                    props.logOutHandler();
                    props.history.push('/');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('authToken')
                }
            }
            else {
                console.log("error");
            }
        }
        checkdata();
    }
    useEffect(() => {
        checktoken()
    }, []);
    useEffect(() => {
        if (popup == true) {
            toast.error(t("We have detected that your account is already in use on another device. To prevent unauthorized access, we have automatically logged you out of this device."), {
                position: toast.POSITION.BOTTOM_CENTER,
                className: 'auto_logout-center'
            });
        }  // toast.error("We have detected that your account is already in use on another device. To prevent unauthorized access, we have automatically logged you out of this device.", {
        //     position: toast.POSITION.BOTTOM_CENTER,
        //     className: 'auto_logout-center'
        // });
    }, [popup])
    return (
        <>
            {props.children}
            {/* Render the children components */}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.user,
        blockUser: state.blockUser,
        isApprovePop: state.isApprovePop
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingHandler: (value) => dispatch({ type: 'IS_LOADING', payload: value }),
        languageHandler: (value) => dispatch({ type: 'LANGUAGE', payload: value }),
        approvePopHandler: (value) => dispatch({ type: 'IS_APPROVEPOP', payload: value }),
        logOutHandler: () => dispatch({ type: "LOG_OUT" }),

    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckLogout)))